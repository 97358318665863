import React, { ReactChild } from 'react';

const IcAllowLocation = (): ReactChild => (
  <svg width="81" height="80" viewBox="0 0 81 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <ellipse opacity="0.1" cx="40.4983" cy="40" rx="39.7444" ry="40" fill="#54BEB3" />
    <ellipse opacity="0.1" cx="40.5008" cy="40" rx="27.8211" ry="28" fill="#54BEB3" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M40.6016 26C34.5263 26 29.6016 30.9247 29.6016 37C29.6016 39.5982 30.503 41.988 32.01 43.87L40.6016 54.5999L49.1923 43.8708C50.6989 41.9881 51.6016 39.5985 51.6016 37C51.6016 30.9247 46.6758 26 40.6016 26ZM40.6016 51.4001L33.5712 42.62C32.3381 41.08 31.6016 39.1278 31.6016 37C31.6016 32.0293 35.6308 28 40.6016 28C45.5713 28 49.6016 32.0294 49.6016 37C49.6016 39.1274 48.8641 41.0799 47.6308 42.6212L40.6016 51.4001ZM37.6006 37C37.6006 35.3433 38.9439 34 40.6006 34C42.2573 34 43.6006 35.3433 43.6006 37C43.6006 38.6567 42.2573 40 40.6006 40C38.9439 40 37.6006 38.6567 37.6006 37ZM40.6006 32C37.8393 32 35.6006 34.2387 35.6006 37C35.6006 39.7613 37.8393 42 40.6006 42C43.3619 42 45.6006 39.7613 45.6006 37C45.6006 34.2387 43.3619 32 40.6006 32Z"
      fill="#54BEB3"
    />
  </svg>
);

export default IcAllowLocation;
