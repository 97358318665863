import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { Icon, useUpdateModal } from '@zydalabs/storefront-components';
import useTranslation from 'next-translate/useTranslation';

import { CoordsType } from 'common/types';
import { IcAllowLocation } from 'common/icons';
import {
  NAVIGATOR_PERMISSION_ACTIONS,
  NAVIGATOR_PERMISSION_QUERY_NAMES,
  NAVIGATOR_PERMISSION_STATES,
  USER_AGENT_TYPES,
  VENDOR_TYPES,
} from 'common/enums';
import { useGlobalSnackBar } from '../hooks';

export const useAllowLocation = (setCurrentAreaCoords?: (location: CoordsType) => void) => {
  const { t } = useTranslation('address');
  const updateModal = useUpdateModal();
  const router = useRouter();
  const [locationPermissionState, setLocationPermissionState] = useState<NAVIGATOR_PERMISSION_STATES>();

  // Event Listener
  let locationPermissionEventListener;

  const [currentLocation, setCurrentLocation] = useState<CoordsType>();
  const [isFetchingUserLocation, setIsFetchingUserLocation] = useState(false);
  const [locationPermission, setLocationPermission] = useState(undefined);

  const [snackBarDetails, setSnackBarDetails] = useGlobalSnackBar();

  const { askForLocation, showLocationIsDenined } = router?.query;

  const isInstagramBrowser = () => {
    const userAgent = window?.navigator.userAgent || '';
    return userAgent.indexOf(USER_AGENT_TYPES.INSTAGRAM) !== -1;
  };

  const shouldAskForLocation = askForLocation === 'true';
  const shouldShowLocationIsDenied = showLocationIsDenined === 'true' && !isInstagramBrowser();

  const showLocationIsDenied = () => {
    setSnackBarDetails({
      show: true,
      setShowSnackBar: () => snackBarDetails.setShowSnackBar(true),
      autoHide: true,
      message: t('permissionDenied'),
      onHide: () => setSnackBarDetails({ show: false, setShowSnackBar: () => snackBarDetails.setShowSnackBar(false) }),
    });
  };

  const getCurrentLocation = () => {
    setIsFetchingUserLocation(true);
    navigator?.geolocation.getCurrentPosition(
      position => {
        setCurrentAreaCoords({ lat: position.coords.latitude, lng: position.coords.longitude });
        setCurrentLocation({ lat: position.coords.latitude, lng: position.coords.longitude });
        setIsFetchingUserLocation(false);
        setLocationPermission(true);
      },
      () => {
        setIsFetchingUserLocation(false);
        showLocationIsDenied();
        setLocationPermission(false);
      },
    );
  };

  const isSafariBrowser = () => {
    const userAgent = window?.navigator.userAgent || '';
    const vendor = window?.navigator.vendor || '';
    return (
      userAgent.indexOf(USER_AGENT_TYPES.SAFARI) !== -1 &&
      vendor.indexOf(VENDOR_TYPES.APPLE) !== -1 &&
      userAgent.indexOf(USER_AGENT_TYPES.INSTAGRAM) === -1
    );
  };

  const getGeoLocationPermissionState = async () => {
    const getLocationPermission = await navigator?.permissions?.query({
      name: NAVIGATOR_PERMISSION_QUERY_NAMES.GEO_LOCATION,
    });
    locationPermissionEventListener = getLocationPermission?.addEventListener(NAVIGATOR_PERMISSION_ACTIONS.CHANGE, event => {
      const permissionState = event.target as PermissionStatus;
      if (permissionState.state === NAVIGATOR_PERMISSION_STATES.GRANTED) setLocationPermission(true);
      else setLocationPermission(false);
    });
    const currentLocationPermissionState = getLocationPermission?.state as NAVIGATOR_PERMISSION_STATES;
    setLocationPermissionState(currentLocationPermissionState);
    if (
      [NAVIGATOR_PERMISSION_STATES.GRANTED, NAVIGATOR_PERMISSION_STATES.PROMPT].includes(
        currentLocationPermissionState as NAVIGATOR_PERMISSION_STATES,
      )
    )
      setLocationPermission(true);
    else setLocationPermission(false);
  };

  const getGeoLocationPermission = async () => {
    if (isSafariBrowser()) getCurrentLocation();
    if (isInstagramBrowser() && (locationPermissionState === NAVIGATOR_PERMISSION_STATES.DENIED || !locationPermissionState)) {
      setIsFetchingUserLocation(false);
      setLocationPermission(false);
    } else if (
      locationPermissionState &&
      ![NAVIGATOR_PERMISSION_STATES.DENIED, NAVIGATOR_PERMISSION_STATES.GRANTED].includes(
        locationPermissionState as NAVIGATOR_PERMISSION_STATES,
      )
    ) {
      updateModal({
        isOpen: true,
        variant: 'floating',
        title: t('allowLocation'),
        description: t('locationDesc'),
        confirmButtonText: t('allowNow'),
        cancelButtonText: t('later'),
        showCloseIcon: false,
        onCancel: () => updateModal({ isOpen: false }),
        onConfirm: () => {
          updateModal({ isOpen: false });
          getCurrentLocation();
        },
        icon: <Icon icon={IcAllowLocation} width="100%" />,
      });
    } else getCurrentLocation();
  };

  useEffect(() => {
    getGeoLocationPermissionState();
    if (shouldAskForLocation) {
      getGeoLocationPermission();
    } else if (shouldShowLocationIsDenied) {
      showLocationIsDenied();
    }
    return () => locationPermissionEventListener;
  }, []);

  return {
    currentLocation,
    getCurrentLocation,
    locationPermissionState,
    isSafariBrowser,
    locationPermission,
    isLoading: isFetchingUserLocation,
  };
};
